<template>
    <div id="FormError">
        <div>
            <input v-if="formulario?.email" id="email" type="hidden" :placeholder="formulario?.email" :value="formulario?.email">
        </div>
        <div class="d-flex row justify-content-center">
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9 col-xl-9 col-xxl-12 p-4">

                <div id="onlyDesktop" class="pb-5 pt-1 m-3 ">
                    <h1 id="onlyDesktop" class="font-weight-bold" >Lamentamos no poder ayudarte en ésta ocasión</h1>
                    <h4 v-if="solicitud.numeroestado == 9100"><strong>Queremos avisarle que:</strong></h4>
                    
                </div>
             
                <!-- Mensajes de Error -->
            
                <div class="pb-5 mt-3 pl-0">
                    <div class="red-border">
                        <p class="messageinicio">{{windowWidth >= 992 ? "Tu solicitud no cumple con algunos de los requisitos para ofrecerle el servicio" : "Tu solicitud no cumple con algunos de los requisitos"}}</p>
                        <br>
                        <h5 class="messageinicio red-warning font-weight-bold">{{errorTitle}}</h5>
                        <br>
                        <p class="messageinicio">{{errorDescription}}</p>
                        <!-- Añadimos input oculto para gestion de campañas de marketing de google-->
                        <input v-if="formulario && formulario.email" id="email" type="hidden" :placeholder="formulario.email" :value="formulario.email">
                    </div>
                </div>
                

               
                <div class="pb-5 message d-none d-sm-none d-md-none d-lg-block d-xl-block">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <!-- <p style="font-size: 22px;">Queremos agradecerte el tiempo que has invertido en rellenar la solicitud.<br> Valoramos tu interés en el producto.
                            </p><br> -->
                            <p style="font-size: 22px;">Nuestro objetivo es brindarte soluciones financieras rápidas, sin embargo, existen ciertos criterios y requisitos que debemos cumplir para poder ofrecerte nuestros servicios.
                            </p>
                        </div>
                    </div>
                </div>

                <div id="afiliado" v-if="this.rechazado" >
                    <div id="preocupes">
                        <div id="texto-preocupar">
                            ¡No te preocupes!
                        </div>
                    </div>
                    <div id="ayudar">
                        <div id="texto-preocupar" style="width: 240px; margin: 0 auto;">
                            Hay otra empresa que te puede ayudar
                        </div>
                    </div>
                </div>
                

                <div id="logos_afiliados" style="display:flex; justify-content: center; margin-top: 10px; margin-bottom: 60px; gap: 8px;" class="row" v-if="this.rechazado">
                    <img width="228" height="100" src="@/assets/img/logo_ibancar_azul.svg" alt="Ibancar" class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3">
                    <div class="Arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <img v-if="this.imageRechazo == 'crezu'" width="400" height="90" src="@/assets/img/crezu.svg" alt="Ibancar" class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3">
                    <img v-else-if="this.imageRechazo == 'fcredito'"  id="fcredito" width="100" height="150" src="@/assets/img/fiestacredito2.svg" alt="Ibancar" class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3">

                </div>
                
                
                <div class="text-center" v-if="this.rechazado">
                    <button @click="irAfiliado()" id="nueva-solicitud" :class="'btn-orange '+this.imageRechazo" v-text="this.nameRechazo"></button>
                </div>
               <br>
               
                 <div class="text-center">
                    <button @click="resetSolicitud()" id="nueva-solicitud" style="border: none; background: none;font-weight: bold;">NUEVA SOLICITUD</button>
                </div> 
                <br>
                <div class="p-4">
                    <div class="row text-center">
                        <div class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3 align-items-center flex">
                            <div class="col-4 col-sm-5 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <a href="tel:+525612673878">
                                    <svg class="" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="icon" d="M3.62 8.03393C5.06 10.8691 7.38 13.1933 10.21 14.6359L12.41 12.4319C12.69 12.1514 13.08 12.0713 13.43 12.1815C14.55 12.5521 15.75 12.7525 17 12.7525C17.2652 12.7525 17.5196 12.8581 17.7071 13.0459C17.8946 13.2338 18 13.4886 18 13.7543V17.2607C18 17.5264 17.8946 17.7812 17.7071 17.9691C17.5196 18.157 17.2652 18.2625 17 18.2625C12.4913 18.2625 8.1673 16.4682 4.97918 13.2743C1.79107 10.0803 0 5.74845 0 1.23156C0 0.965858 0.105357 0.711041 0.292893 0.523163C0.48043 0.335285 0.734784 0.229736 1 0.229736H4.5C4.76522 0.229736 5.01957 0.335285 5.20711 0.523163C5.39464 0.711041 5.5 0.965858 5.5 1.23156C5.5 2.48383 5.7 3.68602 6.07 4.80806C6.18 5.1587 6.1 5.54941 5.82 5.82992L3.62 8.03393Z" fill="#004680"/>
                                    </svg>
                                </a>
                            </div>
                            <div class="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9 col-xxl-9 text-left">
                                <div><strong>Teléfono</strong></div>
                                <a href="tel:+525555661583 ">
                                    <div>+52 55 5566 1583</div>
                                </a>
                            </div>
                        </div>

                        <div class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3 align-items-center flex">
                            <div class="col-4 col-sm-5 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <a href="mailto:info@ibancar.mx">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27 0H3C1.35 0 0.015 1.35 0.015 3L0 21C0 22.65 1.35 24 3 24H27C28.65 24 30 22.65 30 21V3C30 1.35 28.65 0 27 0ZM3 6L15 13.5L27 6V21H3V6ZM3 3L15 10.5L27 3H3Z" fill="#FFA500" />
                                    </svg>
                                </a>
                            </div>
                            <div class="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9 col-xxl-9 text-left">
                                <div><strong>Email</strong></div>
                                <a href="mailto:info@ibancar.mx">
                                    <div>info@ibancar.mx</div>
                                </a>
                            </div>
                        </div>

                        <div class="row col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pb-3 pr-3 align-items-center flex">
                            <div class="col-4 col-sm-5 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
                                    <path d="M25.6098 4.22917C22.7875 1.51042 19.0244 0 15.0523 0C6.79442 0 0.104528 6.44444 0.104528 14.3993C0.104528 16.9167 0.836237 19.434 2.09059 21.5486L0 29L7.94425 26.9861C10.1394 28.0937 12.5436 28.6979 15.0523 28.6979C23.3101 28.6979 30 22.2535 30 14.2986C29.8955 10.5729 28.4321 6.94792 25.6098 4.22917ZM22.2648 19.5347C21.9512 20.3403 20.4878 21.1458 19.7561 21.2465C19.1289 21.3472 18.2927 21.3472 17.4564 21.1458C16.9338 20.9444 16.2021 20.7431 15.3659 20.3403C11.6028 18.8299 9.19861 15.2049 8.98955 14.9028C8.78049 14.7014 7.42161 12.9896 7.42161 11.1771C7.42161 9.36458 8.36237 8.55903 8.67596 8.15625C8.98955 7.75347 9.40766 7.75347 9.72125 7.75347C9.93031 7.75347 10.2439 7.75347 10.453 7.75347C10.662 7.75347 10.9756 7.65278 11.2892 8.35764C11.6028 9.0625 12.3345 10.875 12.439 10.9757C12.5436 11.1771 12.5436 11.3785 12.439 11.5799C12.3345 11.7812 12.23 11.9826 12.0209 12.184C11.8118 12.3854 11.6028 12.6875 11.4983 12.7882C11.2892 12.9896 11.0801 13.191 11.2892 13.4931C11.4983 13.8958 12.23 15.0035 13.3798 16.0104C14.8432 17.2187 15.993 17.6215 16.4112 17.8229C16.8293 18.0243 17.0383 17.9236 17.2474 17.7222C17.4564 17.5208 18.1882 16.7153 18.3972 16.3125C18.6063 15.9097 18.9199 16.0104 19.2334 16.1111C19.547 16.2118 21.4286 17.1181 21.7422 17.3194C22.1603 17.5208 22.3693 17.6215 22.4739 17.7222C22.5784 18.0243 22.5784 18.7292 22.2648 19.5347Z" fill="#48C95F"/>
                                </svg>
                            </div>
                            <div class="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9 col-xxl-9 text-left">
                                <div><strong>Whatsapp</strong></div>
                                <a href="https://api.whatsapp.com/send?l=es&phone=525612673878&text=Hola,%20solicito%20informacion%20sobre%20préstamo%20con%20el%20coche%20como%20aval.%20Gracias" rel="nofollow noopener" target="_blank">
                                    <div>+52 525555661583</div>
                                </a>
                            </div>
                        </div>  

                    </div>
                </div>
                
                <br>


                
                <!-- <div v-show="checkVenta" id="adt-widget-placeholder"></div> -->
                <div>
                    <div class="mb-5">
                        <h5>¿Te has confundido rellenando los datos?</h5>
                        <br>
                        <p class="text-justify">En caso de error, llámanos al <a href="tel:+525555661583">55 5566 1583</a> en horario de Lunes a Viernes de <strong>9:00</strong> a <strong>18:00</strong>, o envíanos un email a <a href="mailto:hola@ibancar.mx">hola@ibancar.mx</a></p>
                    </div>
                </div> 
            </div>

            <!-- Se comenta este trozo para que no aparezca Banner Fizmo
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-4">
                <p>
                    Según los datos aportados, te recomendamos <strong>solicitar hasta 10.000€</strong> con nuestro colaborador donde no necesitas aval de coche.
                </p>
                <div class="text-center pt-3">
                    <a :href="urlVenta" target="_blank">
                        <img width="456" height="456" class="imgFinzmo" src="@/assets/img/error_img.webp" alt="Ibancar">
                    </a>
                </div>
            </div>
            -->
        </div>
        <!-- <div class="bg d-flex justify-content-around align-items-center">
            <div>
                <h2 id="lamentamos" class="title">Lamentamos no poder ayudarte en esta ocasión</h2>
            </div>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { mapState } from 'vuex'

let recaptchaScript = document.createElement('script')
recaptchaScript.setAttribute('src', 'https://cdn.adtr-ct.com/widgets/compare-loans/js/client/widget.client.min.js')
document.head.appendChild(recaptchaScript)

export default {
    name: 'FormularioError',
    store,
    data: function(){
        return{
            codigo: '',
            urlVenta: '',
            checkVenta: true,
            urlAfiliado: "",
            nameRechazo: "",
            imageRechazo: "",
            rechazado: 0,
            windowWidth: window.innerWidth,
        }
    },
    components:{
        
    },
    computed: mapState({
        step: state => state.formulario.step,
        api_token: state => state.api_token,
        formulario: state => state.formulario,
        data_matricula: state => state.formulario.data_matricula,
        solicitud: state => state.solicitud,
        errorTitle: function(){
            let error = '';
            // Todos los estados se forzan a ser de tipo int
            switch (typeof(this.solicitud.numeroestado) === 'string' ? parseInt(this.solicitud.numeroestado) : this.solicitud.numeroestado) {
                //Pregunta 1
                case 9706:
                    error = 'El auto no está pagado';
                break;
                //Pregunta 2
                case 9206:
                    error = 'Sin vehículo a nombre del cliente';
                break;
                //Pregunta 3
                case 9703:
                    error = '"Ingresos no comprobables"';
                break;
                //error en CP
                case 9702:
                    error = '"Código postal fuera de CDMX, Estado de México, Morelos, Querétaro y Puebla."';
                    break;
                case 9701:
                    error = 'Hemos revisado que tu coche no tiene la tasación mínima';
                break;
                // Denegar por antiguedad
                case 9320:
                    error = 'Vehículo de más de 15 años de antigüedad';
                break;
                // Denegar por baja tasación
                case 9360:
                    error = 'Valor del auto por debajo del mínimo';
                break;
                // Denegar por kilometraje
                case 9220:
                    error = 'Kilometraje mayor al máximo permitido';
                break;
                case 9200:
                    error = 'Lamentablemente a día de hoy no trabajamos con coches ubicados en su zona';
                break;
                case 9080:
                    error = 'Solicitud eliminada';
                break;
                case 9100:
                    error = 'Su solicitud fue cerrada';
                break;
                case 9255:
                    error = 'Contrato activo';
                break;
                //case '9360':
                //case '9330':
                default:
                    error = 'Error en la solicitud';
                break;
            }
            return error;
        },
        errorDescription: function(){
            let error = "";
            // Todos los estados se forzan a ser de tipo int
            switch (typeof(this.solicitud.numeroestado) === 'string' ? parseInt(this.solicitud.numeroestado) : this.solicitud.numeroestado) {
                case 9203:
                    error = 'No podemos continuar, ya que requerimos el último recibo de nómina para comprobación de ingresos.';
                break;
                case 9220:
                    error = 'No podemos continuar, ya que el kilometraje de tu auto es mayor al máximo que permitimos para poder utilizarlo como garantía del préstamo.';
                break;
                case 9080:
                    error = 'Parece que usted ha decidido eliminar esta solicitud. Si lo desea puede realizar una nueva.';
                break;
                case 9100:
                    error = 'La solicitud fue cerrada hace unos días. Si desea continuar, por favor vuelva a realizar una nueva solicitud o póngase en contacto con nosotros por alguno de estos medios, en el horario de Lunes a Viernes de 9:00 a 20:00 y Sábados de 10:00 a 14:00. Gracias';
                break;
                case 9330:
                case 9706:
                    error = 'No podemos continuar, ya que su auto no está totalmente pagado, o no cuenta con la factura a tu nombre y no puede usarse como garantía del préstamo';
                break;
                case 9206:
                    error = 'No podemos continuar, ya que se requiere un auto para utilizarse como garantía del préstamo.';
                break;
                // case 9360:
                case 9701:
                    error = 'Lamentablemente con los datos del coche recibidos no podemos continuar con la solicitud. Si tuviera otro vehículo en la unidad familiar no dudes en contactar con nosotros';
                break;
                case 9200:
                    error = 'Actualmente nuestro servicio es prestado en territorio peninsular y baleares. Esperamos en un futuro poder ampliar a más zonas.';
                break;
                case 9255:
                    error = 'Actualmente tiene un contrato en vigor con nosotros, por lo que no podemos tramitar una nueva solicitud.';
                break;
                case 9703:
                    error = 'No podemos continuar, ya que requerimos el último recibo de nómina para comprobación de ingresos.';
                    break;
                // Denegar por antiguedad
                case 9320:
                    error = 'No podemos continuar, ya que admitimos únicamente autos con máximo 15 años de antigüedad para ser utilizados como garantía del préstamo.';
                    break;
                // Denegar por baja tasación
                case 9360:
                    error = 'No podemos continuar, ya que el valor de tu auto es menor al mínimo que requerimos para utilizarlo como garantía del préstamo.';
                    break;
                case 9702:
                    error = 'No podemos continuar, ya que por el momento no tenemos cobertura fuera de CDMX, Estado de México, Morelos, Querétaro y Puebla, muy pronto podremos atenderte.'
                    break;
                default:
                    error = "Lo sentimos pero parece que no hemos podido determinar el motivo de la denegación de la solicitud de préstamo";
                    break;
            }
            return error;
        }
    }),
    methods: {
        getWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        resetSolicitud: async function(){
            await store.commit('resetSolicitud');
            this.formulario.data_matricula.marca =  0 ;
            this.formulario.data_matricula.modelo =  0;
            this.formulario.data_matricula.kilometraje = 0;
            this.formulario.data_matricula.version = 0 ;
            this.formulario.kms = '';
            this.$router.push({path: '/', hash: ''});
        },
        irAfiliado: function(){
            setTimeout(window.location = this.urlAfiliado);
        }
    },
    mounted: function(){
        if(!this.formulario?.email){
            store.commit('setEmail', this.solicitud?.cliente?.def_email1)
        }
        this.preload = true;
        store.commit('setStep', 1); //Resetear pasos por si no le da al botón
        this.codigo = this.$route.params.codigo;
        // Buscar id solicitud
        var idSolicitud = this.codigo.slice(14);
        store.commit('setTokenEnlace', this.codigo);
        store.commit('setIdSolicitud', idSolicitud);
        
        // axios.get(process.env.VUE_APP_AXIOS_BASE_URL+'get/solicitud/web/'+this.codigo)
        // .then((resp) => {
        //     store.commit('setSolicitud', resp.data.data);
        //     this.preload = false;
        //     this.urlVenta = "http://bit.ly/2Pnfyz5";
        //     this.urlVenta = 'https://ibancar.com/redireccion_solicitud.php?id_solicitud='+this.solicitud.id+'&procedencia=banner&nombre_afiliado=Finzmo&codigo_afiliado=101'
        //     this.checkVenta = this.solicitud.web != '141'; // Quitamos panel venta para afiliado 141 Lendo
        // });
        
        //para un futuro uso
        // axios.get('http://127.0.0.1:8000/api/v1/get/solcredito/'+this.codigo).then((resp) => {
        //     this.urlAfiliado = resp.data;
        //     //setTimeout(location.href = this.urlAfiliado,3000);

        // })
        const menu = document.querySelector('#navigation_menu');
        if (window.innerWidth >= 992) {
            menu.style.display = 'block';
        }else{
            menu.style.display = 'block';
        }
        window.addEventListener('resize', this.getWindowWidth);
        
        // Evento datalayer para google ads tagManager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'solicitud_rechazada'
        });
    },
    created() {
        this.getWindowWidth();
        this.codigo = this.$route.params.codigo;
        // Buscar id solicitud
        var idSolicitud = this.codigo.slice(14);

        axios.get(process.env.VUE_APP_AXIOS_BASE_URL + 'get/redireccion/mx/' + idSolicitud)
            .then((resp) => {

                if(resp != 0){
                    this.rechazado = resp.data;
                switch (this.rechazado.id_rechazado) {
                    case 253:
                        this.nameRechazo = "IR A CREZU AHORA >"
                        this.imageRechazo = "crezu"
                        this.urlAfiliado = 'https://track.leadbazaar.co/click?pid=7004&offer_id=404&sub1=' + idSolicitud;
                        setTimeout(function () {
                            window.open('https://track.leadbazaar.co/click?pid=7004&offer_id=404&sub1=' + idSolicitud, '_blank');
                        }, 5000);
                        break;
                    default:
                        break;
                }
                } else {
                    setTimeout(function () {
                        this.$router.push({ path: '/', hash: null });
                    }, 5000);
                    throw Error("Error en carga");
                }

            })
        
        
    },
    watch: {
        windowWidth(value) {
            const menu = document.querySelector('#navigation_menu');
            if (value >= 992) {
                menu.style.display = 'block';
            }else{
                menu.style.display = 'block';
            }
        }
    },
}
</script>

<style scoped lang="scss">

.messageinicio{
    color: #333;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.message{
    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    text-align: justify;
}
#fcredito{
    margin-top: -40px;
}

#onlyDesktop {
    font-family: 'Montserrat-Medium';
}

#FormError{
    margin-top: 100px;
    min-height: 100vh;
}
.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  line-height: auto;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bg {
    margin-top: 9vh;
    background-size: cover;
    background-color: #DC143C;
    color: #fff;
    height: 16vh;
}
.red-warning {
    font-family: 'Montserrat-Medium';
    color: #DC143C;
}
.red-border {
    width: 100%;
    padding-left: 30px;
    border-left: 2px solid #DC143C;
}
#nueva-solicitud {
    font-size: 17px !important;
    line-height: 1.4rem;
}
.black {
    color: #333;
}

#adt-widget-placeholder {
    padding-top: 20px;
}
#afiliado{
    margin: 0 auto;
    width: 400px;
    margin-bottom: 43px;
}
#preocupes{
    height: 47px;
    border-radius: 6px;
    background-color: rgba(255, 165, 0, 0.34);


}
#ayudar{
    height: 86px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

#texto-preocupar{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    font-size: 22px;
    color: #004680;
    line-height: 32.8px;
    font-weight: 600;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;

}

#tres_segundos{
    width: 342px;
    height: 108px;
    margin: 0 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    line-height: 149.5%;
    color: #004680;
    font-weight: 500;
    /* or 27px */

    text-align: center;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  font-size: 62.5%;
}
body{
  background-color: black;
}
.Arrow{
    margin-top: 60px;
    display: flex;
        
    flex-direction: row;
    gap: 25px;
}
.Arrow span{

  width: 24px;
  height: 24px;
  border-bottom: 5px solid #004680;
  border-right: 5px solid #004680;
  transform: rotate(-45deg);
  margin: -1rem;
  animation: scroll 2s infinite;

}
.Arrow span:nth-child(2){
  animation-delay: .2s;
}
.Arrow span:nth-child(3){
  animation-delay: .4s;
}
.Arrow span:nth-child(4){
  animation-delay: .5s;
}

@keyframes scroll{
  0%{
    opacity: 0;
    transfrom: rotate(-45deg) translate(-20px, -20px);
  } 
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transfrom: rotate(-45deg) translate(20px, 20px);
  }


}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


@include media-breakpoint-between(xs, md) {

    .message{
        color: #333;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
        width: 65vw;
        text-align: justify;
        margin-left:5%;
    }
    .messageinicio{
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    #onlyDesktop {
        margin-left:0rem;
        font-size: 28px;

    }
    #texto-preocupar{
    font-size: 18px;
    }

    .btn-orange {
        width: 90vw;
        font-size: 18px;
        border-radius: 30px;
    }
    .imgFinzmo{
        width: 100%;
        height: auto;
    }
    #lamentamos {
        line-height: 1.8rem;
        font-size: 1.6rem;
    }

    #afiliado{
        margin: 0 auto;
        width: 300px;
        margin-bottom: 43px;
    } 
    
    .Arrow span{
        transform: rotate(45deg);
        width: 24px;
        height: 24px
    }
    .Arrow{
        display: flex;
        margin-top: 0px;
        flex-direction: column;
        gap: 25px;
    }

    #fcredito{
        margin-left: 45px;
    }

    // #logos_afiliados img{
    //     height: 80px;
    // }

    @keyframes scroll{
        0%{
            opacity: 0;
            transfrom: rotate(45deg) translate(-20px, -20px);
        } 
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
            transfrom: rotate(45deg) translate(20px, 20px);
        }
    }
}

@include media-breakpoint-between(md, lg) {
    #texto-preocupar{
        font-size: 18px;
    }
    #onlyDesktop {
        margin-left:2rem;
    }
    .btn-orange {
        width: 45vw;
        font-size: 21px;
        border-radius: 30px;
    }

    .Arrow{
        margin-top: 60px;
        display: flex;   
        flex-direction: row!important;
        gap: 24px;
        margin-right: 20px;
    }
    .Arrow span{

        width: 24px;
        height: 24px;
        border-bottom: 5px solid #004680;
        border-right: 5px solid #004680;
        transform: rotate(-45deg);
        margin: -1rem;
        animation: scroll 2s infinite;

    }

    @keyframes scroll{
    0%{
        opacity: 0;
        transfrom: rotate(45deg) translate(-20px, -20px);
    } 
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transfrom: rotate(45deg) translate(20px, 20px);
    }
}
    
    
}

@include media-breakpoint-between(lg,xl){
    #logos_afiliados{
        gap: 60px!important;
    }
    #onlyDesktop {
        font-size: 36px;
        margin-top:1.8rem;
        padding-left: 0px;    }
}

@include media-breakpoint-between(xl, xxl) {
    .btn-orange {
        width: 25vw;
        font-size: 21px;
        border-radius: 30px;
    }
    #onlyDesktop {
        margin-left:-1rem;
    }
}
</style>